import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import { BrowserRouter as Router } from "react-router-dom";

import { ApolloClientProvider } from "lib/apollo";
import { Provider } from "react-redux";
import Store from "redux/store";

import { AuthProvider } from "./authProvider";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Theme } from "theme";

export const AppProvider = (props) => {
  return (
    <Provider store={Store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={Theme}>
          <AuthProvider>
            <ApolloClientProvider>
              <CssBaseline />
              <Router>{props.children}</Router>
            </ApolloClientProvider>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

export const BookDemoButton = React.memo((props) => {
  const {t} = useTranslation();
  const { onClick, color, fontColor, ...otherProps } = props;
  const navigateToBookDemo = () => {
    const element = document.getElementById("bookDemo");
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
    if (onClick) onClick();
  };
  return (
    <Button
      onClick={navigateToBookDemo}
      {...otherProps}
      key="BookDemo"
      variant="outlined"
      sx={{
        my: 2,
        backgroundColor: props.starthovered ? color : "transparent",
        color: fontColor,
        borderRadius: "25px",
        border: "2px solid " + color,
        textTransform: "none",
        "&:hover": {
          backgroundColor: color,
          border: "2px solid " + color,
          color: fontColor,
        },
        ...otherProps["sx"],
      }}
    >
      {t("bookDemo")}
    </Button>
  );
});

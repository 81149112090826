import React from "react";
import { Box, Grow, Button } from "@mui/material";
import { Divider } from "@mui/material";
import { MobileMenuItem } from "./mobileMenuItem";
import { BookDemoButton } from "../misc/bookDemoButton";
import i18next from "i18next";

const languages = ["en", "pl", "ar"]

export const MobileMenu = React.memo((props) => {

  const listItemClick = (elementId) => {
    props.onClick(elementId);
  };

  const languageStr = i18next.language.split('-')[0].toLowerCase();

  const [currL, setCurrL] = React.useState(
    languages.findIndex((x) => x === languageStr)
  );

  const onLanguageClick = () =>{
    let nL = currL + 1;
    if( nL > 2 ) nL = 0;
    setCurrL(nL);
    i18next.changeLanguage(languages[nL]);
  }


  return (
    <Grow in={props.isMenuOpened} style={{ transformOrigin: "80% 20px 0" }}>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          backgroundColor: "#FF9900",
          top: 0,
          bottom: 0,
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            marginTop: "80px",
            ml: "20px",
            mr: "20px",
            position: "relative",
            height: "calc(100% - 170px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Divider
            sx={{ backgroundColor: "#E0E0E0", opacity: 0.25 }}
            light
          ></Divider>
          <Box sx={{ marginTop: "10px" }}>
            {props.pages.map((page, i) => (
              <MobileMenuItem
                key={i}
                index={i + 1}
                page={page}
                onClick={listItemClick}
              />
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            margin: "auto",
            paddingLeft: "20px",
            paddingRight: "20px",
            backgroundColor: "#FF9900",
          }}
        >
          <Divider
            sx={{
              backgroundColor: "#FFFFFF",
            }}
            light
          ></Divider>
          <BookDemoButton
            color="white"
            fontColor="black"
            starthovered="true"
            sx={{ float: "right", fontWeight: 600, fontSize: "1.1rem" }}
            onClick={props.demoButtonClick}
          />
          <Button
            variant="text"
            sx={{
              float: "left",
              fontSize: "1.25rem",
              color: "white",
              fontWeight: 600,
              marginTop: "10px",
            }}
            onClick={onLanguageClick}
          >
            {i18next.language.split('-')[0].toLowerCase()}
          </Button>
        </Box>
      </Box>
    </Grow>
  );
});

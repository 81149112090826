import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";

import ImgSource from "assets/mainPageImage.jpg";
import { MainHeaderText } from "./mainHeaderText";

const Img = styled("img")``;

export const VideoPage = () => {
  const matches = useMediaQuery("(max-height:541px)");
  return (
    <Box
      sx={{
        minHeight: "max(100vh,330px)",
        width: "100%",
        backgroundColor: "#0E131F",
        position: "relative",
      }}
    >
      <Img
        src={ImgSource}
        sx={{
          opacity: 0.25,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
        }}
      ></Img>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "100%",
          color: "white",
        }}
      >
        <MainHeaderText />
      </Box>
    </Box>
  );
};

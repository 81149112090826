import React from "react";
import { Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CircularIndex } from "./circularIndex";
export const HowItWorksItem = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={matches ? 0 : 4}
      sx={{ paddingTop: "30px", paddingBottom: "30px" }}
    >
      <Grid
        item
        sx={{
          width: {
            xs: 0,
            md: "calc(50vw - min(40vw,630px))",
          },
          height: "100px",
          marginLeft: {
            xs: 0,
            md: "-10px",
          },
        }}
        xs="auto"
      ></Grid>
      <Grid
        item
        sx={{
          textAlign: {
            xs: "center",
            md: "left",
          },
          marginTop: {
            xs: "0px",
            sm: "30px",
            md: 0,
          },
        }}
        xs={matches ? 12 : true}
        order={{xs:2,md:1}}
      >
        <Typography
          variant="h5"
          sx={{
            maxWidth: "80vw",
            margin: matches ? "0 auto" : "0",
            fontFamily: "Aeonik",
          }}
        >
          {props.header}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            maxWidth: "80vw",
            margin: matches ? "0 auto" : "0",
            fontFamily: "Aeonik-Light",
          }}
        >
          {props.description}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: "0",
          justifyContent: "center",
        }}
        xs={matches ? 12 : 5}
        order={{xs:1,md:2}}
      >
        <CircularIndex index={props.index} />
      </Grid>
    </Grid>
  );
};

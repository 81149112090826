import React from "react";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

import ImgSource from "assets/bottomPicture.png";

const Img = styled("img")``;

export const AppImages = () => {
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Img
        src={ImgSource}
        sx={{
          position: "absolute",
          margin: "0 auto",
          width: {
            xs: "80vw",
            sm: "60vw",
            md: "35vw",
          },
          maxWidth: "80vh",
          left: 0,
          right: 0,
          bottom: {
            xs: "-70px",
            md: "-150px",
          },
        }}
      ></Img>
    </Box>
  );
};

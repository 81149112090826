import { createTheme } from "@mui/material/styles";
import * as colors from "./colors";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    md1: 1000,
    md2: 1100,
    lg: 1200,
    xl: 1536,
  },
}

export const Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.PRIMARY_MAIN_COLOR,
      light: colors.PRIMARY_LIGHT_COLOR,
    },
    secondary: {
      main: colors.SECONDARY_MAIN_COLOR,
    },
    action: {
      selected: colors.ACTION_SELECTED,
      hover: colors.ACTION_HOVER,
      disabled: colors.ACTION_DISABLED,
    },
    text: {
      primary: "#ffffff",
    },
  },
  breakpoints: breakpoints,
  typography: {
    allVariants: {
      color: "white",
    },
    h1:{
      fontSize: "6rem",
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: "3rem"
      },
      [`@media screen and (max-height: 541px)`]: {
        fontSize: "3rem"
      }
    },
    h2:{
      fontSize: "4.5rem",
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: "2.25rem"
      },
      [`@media screen and (max-height: 541px)`]: {
        fontSize: "2.25rem"
      }
    },
    h3:{
      fontSize: "3rem",
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: "1.75rem"
      },
      [`@media screen and (max-height: 541px)`]: {
        fontSize: "1.75rem"
      }
    },
    h4:{
      fontSize: "2rem",
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: "1.5rem"
      },
      [`@media screen and (max-height: 541px)`]: {
        fontSize: "1.5rem"
      }
    },
    h5: {
      fontSize: "1.5rem",
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: "1.2rem"
      },
      [`@media screen and (max-height: 541px)`]: {
        fontSize: "1.2rem"
      }
    }
  },
});
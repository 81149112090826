import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { Person } from "./person";

import Kuba from "assets/T_Kuba.png";
import Marcin from "assets/T_Marcin.png";
import Miko from "assets/T_Mikolaj.png";
import Tomek from "assets/T_Tomek.png";
import { useTranslation } from "react-i18next";

const people = [
  {
    name: "Jakub",
    lastName: "Ciecierski",
    position: "CEO & Co-Founder",
    linkedin: "https://www.linkedin.com/in/jakub-ciecierski-96576296/",
    imgSrc: Kuba,
  },
  // {
  //   name: "Tomasz",
  //   lastName: "Kalinowski",
  //   position: "Co-Founder & Sales Manager",
  //   linkedin: "https://www.linkedin.com/in/tomasz-kalinowski-772577119/",
  //   imgSrc: Tomek,
  // },
  {
    name: "Mikołaj",
    lastName: "Leszczyński",
    position: "Co-Founder & Software Engineer",
    linkedin:
      "https://www.linkedin.com/in/miko%C5%82aj-leszczy%C5%84ski-073657116/",
    imgSrc: Miko,
  },
  {
    name: "Marcin",
    lastName: "Niedabylski",
    position: "Co-Founder & Software Engineer",
    linkedin: "https://www.linkedin.com/in/niedabylskim/",
    imgSrc: Marcin,
  },
];

export const Team = (props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minHeight: "150vh",
        paddingTop: "15vh",
        paddingBottom: {
          xs: "30vh",
          sm: "35vh",
          md: "55vh",
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          width: "min(90%,1260px)",
          margin: "0 auto",
          paddingLeft: {
            xs: "5vw",
            sm: 0,
            md: 0,
          },
        }}
        id={props.id}
      >
        {t("pages.team")}
      </Typography>
      <Box
        sx={{
          margin: {
            xs: "50px auto",
            sm: "75px 0 0 80px",
            md: "100px 0 0 120px",
            md2: "100px 0 0 calc(50vw - 480px)",
          },
          width: {
            xs: "80%",
            md2: "75%",
          },
          textAlign: {
            xs: "left",
            md2: "left",
          },
          maxWidth: "550px",
        }}
      >
        <Typography variant="h5" id={props.id}>
          {t("team.header")}
        </Typography>
        <Typography variant="h3" gutterBottom>
          {t("team.description")}
        </Typography>
        <Box
          sx={{ width: "130px", height: "5px", backgroundColor: "#FF9900" }}
        ></Box>
      </Box>
      <Grid
        container
        alignItems="center"
        sx={{
          width: "min(90%,990px)",
          margin: "0 auto",
          justifyContent: {
            xs: "center",
            md2: "flex-start",
          },
          alignItems: "flex-start",
          paddingTop: "5vh",
        }}
      >
        {people.map((person, i) => {
          return (
            <Grid
              key={i}
              item
              xs="auto"
              sx={{
                padding: {
                  xs: 0,
                  sm: "5px",
                  md: "10px",
                },
              }}
            >
              <Person
                name={person.name}
                lastName={person.lastName}
                position={person.position}
                linkedin={person.linkedin}
                imgSrc={person.imgSrc}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ height: "15vh" }}></Box>
    </Box>
  );
};

export const en = {
  translation: {
    pages: {
      product: "About Product",
      features: "Specification",
      cases: "Case Study",
      results: "Results",
      team: "Team",
      contact: "Contact",
    },
    mainPage: {
      header1: "AUGMENTED",
      header2: "VISION",
      description: "Application for smart instructions powered by AI & AR",
    },
    aboutProduct: {
      headerAi: "Artificial Inteligence",
      headerAr: "Augmented Reality",
      header: "We simplify the way of configuring and connecting devices.",
      header3: "Current Situation",
      description:
        "Customers must rely on traditional instructions or use call-centre help to troubleshoot their problems. Often it takes a lot of time to contact someone and decreases user experience with the brand. Customers do not have right tools to fix simple problems on their own. We believe that instructions for the installation process of your electronic devices should be as simple as possible.",
      descriptionAi:
        "Augmented Vision provides AI instruction that cuts call center overhead and improves the customer user experience. Algorithms detect devices, sockets and cables. Customers can make mistakes and AI will inform them about it. All packed in user friendly interface.",
      descriptionAr:
        "AR holograms, which are displayed directly on customer’s devices, guide them step by step through the entire setup process.",
      howItWorks: {
        header: "How does it work?",
        header1: "Open smart instruction",
        description1: "Customer opens your application",
        header2: "Show device to the camera",
        description2:
          "Camera recognizes customer’s device, sockets and cables thanks to our AI algorithms.",
        header3: "Set up the device",
        description3:
          "Our AI algorithms guide customer through the installation process with the power of AR holograms",
      },
    },
    features: {
      header1: "Offline & Data Safety",
      description1:
        "The application works in offline mode. All algorithms are computed directly on the mobile device. No need to transfer any data to the remote server.",
      header2: "Real Time Computations",
      description2:
        "AI algorithms work in real time even on low-end devices. Camera data is computed in milliseconds and user receives instructions without having to wait.",
      header3: "Gather Reports",
      description3:
        "The application can collect data about how user interacts with it, what problems with the device occurred and how much time it took to fix the issue.",
    },
    cases: {
      item1: {
        title: "TELECOMS",
        header:
          "AV lowers your helpdesk support costs and improves your clients’ user experience.",
        description:
          "The self-service and user-friendly device installation process positively impacts customer engagement with the brand, while saving telecoms the operational costs related to call centers.",
      },
      item2: {
        title: "HEALTH",
        header:
          "AV reduces potential errors in medical device installation and operation.",
        description:
          "Medical device makers can support clinical personnel in using complex equipment and fast-track device settings.",
      },
      item3: {
        title: "INDUSTRY",
        header: "AV automates the training, onboarding and plant maintenance.",
        description:
          "Employees shelve aside the traditional manual and scan the machine using their phone/tablet to see AR holograms with information about operating instructions, servicing or maintenance history.",
      },
    },
    results: {
      header: "Results of testing AV in the biggest polish telecom",
      item1: {
        result: "40%",
        description:
          "Fewer calls requesting help regarding the installation process than before.",
      },
      item2: {
        result: "84%",
        description:
          "Customers rated solution as very useful for connecting and setting up the equipment.",
      },
    },
    team: {
      header: "Who are we?",
      description: "A well-coordinated team with extensive experience",
    },
    bookDemo: "Book a demo",
    contactForm: {
      header: "Keep in touch with us!",
      description: "Leave a request to connect or ask questions.",
      name: "Name",
      email: "Email address",
      phone: "Phone",
      company: "Organization",
      country: "Country",
      nameError: "Invalid name",
      emailError: "Invalid email",
      thanks: "Thanks, we will contact you soon!",
    },
  },
};

import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import VimeoPlayer from "react-player/vimeo";
import { CaseItem } from "./caseItem";

import Telecom from "assets/telecom.png";
import Health from "assets/health.png";
import Industry from "assets/industry.png";

export const Cases = (props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        paddingTop: "15vh",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          width: "min(90%,1260px)",
          margin: "0 auto",
          paddingLeft: {
            xs: "5vw",
            sm: 0,
            md: 0,
          },
        }}
        id={props.id}
      >
        {t("pages.cases")}
      </Typography>
      <Box
        sx={{
          margin: "100px auto",
          width: "min(90%,1260px)",
          height: "calc(0.5625 * min(90vw,1260px))",
          border: "1px white solid",
          borderRadius: "3px",
        }}
      >
        <VimeoPlayer
          url="https://vimeo.com/669223290"
          controls={true}
          width="100%"
          height="100%"
        />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          width: "min(90%,1260px)",
          margin: {
            xs: "50px auto",
            md: "100px auto",
          },
        }}
      >
        <Grid item xs={12}>
          <CaseItem
            image={Telecom}
            title={t("cases.item1.title")}
            name={t("cases.item1.header")}
            description={t("cases.item1.description")}
          />
        </Grid>
        <Grid item xs={12}>
          <CaseItem
            image={Health}
            title={t("cases.item2.title")}
            name={t("cases.item2.header")}
            description={t("cases.item2.description")}
            isRight={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CaseItem
            image={Industry}
            title={t("cases.item3.title")}
            name={t("cases.item3.header")}
            description={t("cases.item3.description")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

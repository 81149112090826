import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ResultItem } from "./resultItem";
import { DashedLine } from "../common";

export const Results = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h2"
        sx={{
          width: "min(90%,1260px)",
          margin: "0 auto",
          paddingLeft: {
            xs: "5vw",
            sm: 0,
            md: 0,
          },
        }}
        id={props.id}
      >
        {t("pages.results")}
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingBottom: "50px",
          marginTop: {
            xs: "50px",
            md: "150px",
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "min(80%,600px)",
              float: {
                xs: "none",
                md: "right",
              },
              margin: {
                xs: "0 auto",
                md: "0 30px 0 0",
              },
              marginLeft: "10px",
            }}
          >
            <Typography
              variant="h4"
              paragraph
              id={props.id}
              sx={{ maxWidth: "400px" }}
            >
              {t("results.header")}
            </Typography>
            <Box
              sx={{ width: "130px", height: "5px", backgroundColor: "#FF9900" }}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <DashedLine />
        <Grid item xs={12}>
          <ResultItem
            result={t("results.item1.result")}
            description={t("results.item1.description")}
          />
        </Grid>
        <DashedLine />
        <Grid item xs={12}>
          <ResultItem
            result={t("results.item2.result")}
            description={t("results.item2.description")}
          />
        </Grid>
        <DashedLine />
      </Grid>
    </>
  );
};

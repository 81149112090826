import React, {useCallback} from "react";
import { Typography, Stack, Box, Button } from "@mui/material";

export const MobileMenuItem = React.memo((props) => {
  const idToString = (id) => {
    return "0" + id;
  };

  const onButtonClicked = useCallback((id) => {
    if (props.onClick) props.onClick(id);
  },[props]);

  return (
    <Box sx={{ paddingTop: "5px" }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="caption" sx={{ color: "#E0E0E0", opacity: 0.75 }}>
          {idToString(props.index)}
        </Typography>
        <Box
          sx={{
            width: "45px",
            height: "3px",
            backgroundColor: "#E0E0E0",
            opacity: 0.75,
          }}
        ></Box>
      </Stack>
      <Button
        variant="text"
        sx={{
          width: "100%",
          marginLeft: "27px",
          color: "white",
          textTransform: "none",
          fontSize: "1.25rem",
          fontWeight: 600,
          justifyContent: "left",
        }}
        underline="none"
        onClick={() => onButtonClicked(props.page.id)}
      >
        {props.page.name}
      </Button>
    </Box>
  );
});

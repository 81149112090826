import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { BookDemoInputForm } from "./bookDemoInputForm";
import { useTranslation } from "react-i18next";

export const BookDemo = (props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#FF9900",
        paddingTop: {
          xs: "50px",
          md: "50px",
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          paddingTop: {
            xs: "100px",
            md: "150px",
          },
          paddingBottom: {
            xs: "50px",
            md: "150px",
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "min(80%,500px)",
              float: {
                xs: "none",
                md: "right",
              },
              margin: {
                xs: "0 auto",
                md: "0 10px 0 0",
              },
              marginRight: "10px",
            }}
          >
            <Typography variant="h5">{t("contactForm.header")}</Typography>
            <Typography variant="h3" gutterBottom sx={{ lineHeight: 1.3 }}>
              {t("contactForm.description")}
            </Typography>
            <Box
              sx={{ width: "130px", height: "5px", backgroundColor: "white" }}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} id={props.id}>
          <Box
            sx={{
              width: "min(80%,500px)",
              margin: {
                xs: "30px auto",
                md: "0 0 0 10px",
              },
            }}
          >
            <BookDemoInputForm />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";
import { Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import { BookDemoButton } from "../misc/bookDemoButton";
import { styled } from "@mui/material/styles";
import AvLogo from "assets/avLogo.svg";

const Img = styled("img")``;

export const MainHeaderText = () => {
  const matches = useMediaQuery("(max-height:541px)");
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md1"));
  const { t } = useTranslation();
  const headerSx = {
    lineHeight: 1,
    letterSpacing: 0,
    fontStyle: "normal",
    fontWeight: 800,
    fontFamily: "Aeonik",
  };
  const subtitleSx = {
    marginTop: "10px",
    maxWidth: "75%",
    position: "absolute",
    bottom: "50px",
  };
  return (
    <Box
      sx={{
        marginLeft: "30px",
        height: "100%",
      }}
    >
      {isDesktop ? (
        <Box sx={{ position: "relative", height: "100%" }}>
          <Typography
            variant="h1"
            sx={{
              lineHeight: 1,
              letterSpacing: 0,
              fontStyle: "normal",
              fontWeight: 600,
              paddingTop: "100px",
              fontFamily: "Aeonik",
            }}
          >
            {t("mainPage.header1")}
          </Typography>
          <Typography gutterBottom variant="h1" sx={headerSx}>
            {t("mainPage.header2")}
          </Typography>
          <Box sx={subtitleSx}>
            <Box
              sx={{
                width: "50px",
                height: "6px",
                backgroundColor: "#FF9900",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Aeonik",
                fontWeight: "400",
                lineHeight: 1.5,
                letterSpacing: "0em",
              }}
            >
              {t("mainPage.description")}
            </Typography>
          </Box>
          <Img
            src={AvLogo}
            sx={{
              right: "50px",
              bottom: "30px",
              position: "absolute",
              height: "300px",
              width: "300px",
            }}
          />
        </Box>
      ) : (
        <Box sx={{ position: "relative", height: "100%" }}>
          <Box sx={{position:"absolute", bottom:"50px"}}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: "500",
              lineHeight: 1.2,
              letterSpacing: "0em",
              maxWidth: "90%",
            }}
          >
            {t("mainPage.description")}
          </Typography>
          <BookDemoButton
            starthovered="true"
            color="#FF9900"
            fontColor="white"
            sx={{
              display: { md1: "none" },
              fontSize: {
                xs: "1.2rem",
                sm: matches ? "1.2rem" : "1.6rem",
              },
            }}
          />
          </Box>
        </Box>
      )}
    </Box>
  );
};

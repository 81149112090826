import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { Stack, Grid, Fade, Slide, AppBar, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MobileMenu } from "./mobileMenu";
import { styled } from "@mui/material/styles";
import AvLogo from "assets/avLogo.svg";

const Img = styled("img")``;

export const MobileNavBar = (props) => {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);
  const [isOnTop, setIsOnTop] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      setIsOnTop(Boolean(window.pageYOffset === 0));
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleOpenNavMenu = () => {
    const newOpened = !isMenuOpened;
    document.body.style.overflow = newOpened ? "hidden" : "scroll";
    setIsMenuOpened(newOpened);
  };

  const handleNavButtonClicked = (elementId) => {
    handleOpenNavMenu();
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  const closeMenu = () => {
    if (isMenuOpened) handleOpenNavMenu();
  };

  const handleLogoVisible = () => {
    if (isMenuOpened) return true;
    return isOnTop;
  };

  return (
    <>
      <Slide
        appear={true}
        direction="down"
        in={handleLogoVisible()}
        unmountOnExit={true}
      >
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            zIndex: 3,
          }}
        >
          <Toolbar disableGutters>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                flexGrow: 1,
                display: "flex",
                height: "70px",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                sx={{ marginLeft: "5px" }}
              >
                <Img
                  src={AvLogo}
                  sx={{
                    height: "1.95em",
                    width: "1.95em",
                    marginLeft: "15px",
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.65em",
                    marginTop: "3px",
                  }}
                >
                  AV
                </Typography>
              </Stack>
            </Grid>
          </Toolbar>
        </AppBar>
      </Slide>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        sx={{
          color: "white",
          float: "right",
          position: "fixed",
          right: "5px",
          zIndex: 5,
        }}
      >
        <Fade in={isMenuOpened || !isMenuOpened}>
          {isMenuOpened ? (
            <CloseIcon sx={{ height: "1.95em", width: "1.75em" }} />
          ) : (
            <MenuIcon sx={{ height: "1.95em", width: "1.75em" }} />
          )}
        </Fade>
      </IconButton>
      <MobileMenu
        pages={props.pages}
        isMenuOpened={isMenuOpened}
        onClick={handleNavButtonClicked}
        demoButtonClick={closeMenu}
      />
    </>
  );
};

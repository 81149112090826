import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HowItWorksItem } from "./howItWorksItem";
import { DashedLine } from "../common/";

export const AboutProductHowItWorks = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: "250px",
          paddingBottom: "50px",
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "min(80%,600px)",
              float: {
                xs: "none",
                md: "right",
              },
              margin: {
                xs: "0 auto",
                md: "0 30px 0 0",
              },
              marginRight: "10px",
            }}
          >
            <Typography variant="h3" gutterBottom>
              {t("aboutProduct.howItWorks.header")}
            </Typography>
            <Box
              sx={{ width: "130px", height: "5px", backgroundColor: "#FF9900" }}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <DashedLine />
        <Grid item xs={12}>
          <HowItWorksItem
            index={1}
            header={t("aboutProduct.howItWorks.header1")}
            description={t("aboutProduct.howItWorks.description1")}
          />
        </Grid>
        <DashedLine />
        <Grid item xs={12}>
          <HowItWorksItem
            index={2}
            header={t("aboutProduct.howItWorks.header2")}
            description={t("aboutProduct.howItWorks.description2")}
          />
        </Grid>
        <DashedLine />
        <Grid item xs={12}>
          <HowItWorksItem
            index={3}
            header={t("aboutProduct.howItWorks.header3")}
            description={t("aboutProduct.howItWorks.description3")}
          />
        </Grid>
        <DashedLine />
      </Grid>
    </>
  );
};

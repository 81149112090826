import { useState } from "react";
import { Auth } from "aws-amplify";

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [localCognitoUser, setLocalCognitoUser] = useState(null);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  let isSetting = false;

  const checkIfNewUserEqual = (newUser) => {
    if (!user) return false;
    const sameGroup = user.group === newUser.group;
    const sameGuid = user.guid === newUser.guid;
    const sameToken = user.token === newUser.token;
    let sameAttributes = true;
    for (let [key, value] of Object.entries(newUser.attributes)) {
      if (user.attributes[key] !== value) {
        sameAttributes = false;
        break;
      }
    }
    return sameGroup && sameGuid && sameToken && sameAttributes;
  };

  const handleNewUser = (newUser) => {
    let attributes = newUser.attributes;
    //logging for the first time has attributes in different place
    if (!attributes) attributes = newUser.challengeParam.userAttributes;
    const token = newUser.signInUserSession.accessToken.jwtToken;
    const group =
      newUser.signInUserSession.accessToken.payload["cognito:groups"][0];
    const guid = newUser.signInUserSession.accessToken.payload["username"];

    const equal = checkIfNewUserEqual({
      group: group,
      attributes: attributes,
      jwtToken: token,
      guid: guid,
    });

    if (!equal) {
      console.log("useProvideAuth - Setting new user");
      setUser({
        group: group,
        attributes: attributes,
        jwtToken: token,
        guid: guid,
      });
      setIsLoggedOut(false);
    } else console.log("useProvideAuth handleNewUser - the same");
  };

  const currentAuthenticatedUser = async (onFinished = null) => {
    try {
      //returns value twice
      const cognitoUser = await Auth.currentAuthenticatedUser();
      if (!isSetting) {
        handleNewUser(cognitoUser);
        isSetting = true;
      }
    } catch (error) {
      console.log("useProvideAuth currentAuthenticatedUser error: " + error);
    } finally {
      if (onFinished) onFinished();
    }
  };

  const signIn = async (
    email,
    password,
    onSuccess = null,
    onError = null,
    onNewPasswordRequired = null
  ) => {
    console.log("useProvideAuth signIn - Before");
    try {
      const cognitoUser = await Auth.signIn(email, password);
      setLocalCognitoUser(cognitoUser);
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        if (onNewPasswordRequired) onNewPasswordRequired();
      } else {
        console.log(cognitoUser);
        handleNewUser(cognitoUser);
        if (onSuccess) onSuccess(false);
      }
    } catch (error) {
      if (onError) onError();
      console.log(error);
    }
    console.log("useProvideAuth signIn - after");
  };
  const signOut = async (onSuccess = null) => {
    console.log("useProvideAuth signOut - Before");
    try {
      await Auth.signOut();
      if (onSuccess) onSuccess();
    } catch (exception) {
      console.log(exception);
    } finally {
      setIsLoggedOut(true);
      setUser(null);
      if (isFirstTimeLogin) setIsFirstTimeLogin(false);
    }
    console.log("useProvideAuth signOut - After");
  };
  const forgotPassword = async (email) => {
    try {
      console.log("useProvideAuth: forgotPassword", email);
      await Auth.forgotPassword(email);
    } catch (error) {
      console.log("useProvideAuth error forgotPassword ", error);
    }
  };
  const forgotPasswordSubmit = async (
    userMail,
    verificationCode,
    newPassword,
    onSuccess = null
  ) => {
    try {
      await Auth.forgotPasswordSubmit(userMail, verificationCode, newPassword);
      const cognitoUser = await Auth.signIn(userMail, newPassword);
      if (onSuccess) onSuccess();
      handleNewUser(cognitoUser);
    } catch (error) {
      console.log("useProvideAuth error forgotPasswordSubmit: ", error);
    }
  };
  const completeNewPassword = async (newPassword, onSuccess = null) => {
    try {
      const cognitoUser = await Auth.completeNewPassword(
        localCognitoUser,
        newPassword
      );
      handleNewUser(cognitoUser);
      setIsFirstTimeLogin(true);
      if (onSuccess) onSuccess(true);
    } catch (error) {
      console.log(" useProvideAutherror completeNewPassword", error);
    }
  };
  const updateUserAttributes = async (
    attributesToSubmit,
    onSuccess = null,
    onError = null
  ) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(cognitoUser, attributesToSubmit);
      if (onSuccess) onSuccess();
      const newUser = await Auth.currentAuthenticatedUser();
      handleNewUser(newUser);
    } catch (error) {
      console.log(" useProvideAutherror updateUserAttributes", error);
      if (onError) onError();
    }
  };

  return {
    user,
    isLoggedOut,
    isFirstTimeLogin,
    currentAuthenticatedUser,
    signIn,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    updateUserAttributes,
  };
}

import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MobileNavBar } from "./mobileNavBar";
import { DesktopNavBar } from "./desktopNavBar";

export const NavBar = (props) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md1"));
  return (
    <>
      {isDesktop ? (
        <DesktopNavBar pages={props.pages} />
      ) : (
        <MobileNavBar pages={props.pages} />
      )}
    </>
  );
};

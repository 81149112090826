import React from "react";
import { Box, Typography, Grid } from "@mui/material";

import { styled } from "@mui/material/styles";
const Img = styled("img")``;

export const CaseItem = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: "150px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 5, md2: 5 }}
      >
        <Grid item xs={12} md={3} order={{ xs: 2, md: props.isRight ? 2 : 1 }}>
          <Box
            sx={{
              width: {
                xs: "90%",
                md: "100%",
              },
              maxWidth: "400px",
              margin: {
                xs: "0 auto",
                sm: "0 0 0 50px",
                md: "0 auto",
              },
              float: {
                xs: "none",
                md: props.isRight ? "right" : "left",
              },
              textAlign: {
                xs: "left",
                sm: "left",
                md: "left",
              },
            }}
          >
            <Typography
              variant="h5"
              paragraph
              sx={{
                fontFamily: "Aeonik-Light",
              }}
            >
              {props.title}
            </Typography>
            <Typography variant="h5" paragraph sx={{ fontWeight: 600 }}>
              {props.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                margin: "0 auto",
              }}
            >
              {props.description}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          order={{ xs: 1, md: props.isRight ? 1 : 2 }}
          sx={{
            textAlign: {
              xs: "center",
              md: props.isRight ? "left" : "right",
            },
          }}
        >
          <Img
            src={props.image}
            sx={{
              maxHeight: "600px",
              maxWidth: "95%",
              margin: "0 auto",
            }}
          ></Img>
        </Grid>
      </Grid>
    </Box>
  );
};

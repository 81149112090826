import React from "react";
import { NavButton } from "./navButton";
import Box from "@mui/material/Box";
import { BookDemoButton } from "../misc";
import { useTranslation } from "react-i18next";

const languages = ["en", "pl", "ar"]

export const NavRightBar = () => {
  const { i18n } = useTranslation();
  const languageStr = i18n.language.split('-')[0].toLowerCase();


  const [currL, setCurrL] = React.useState(
    languages.findIndex((x) => x === languageStr)
  );

  const onLanguageClick = () =>{
    let nL = currL + 1;
    if( nL > 2 ) nL = 0;
    setCurrL(nL);
    i18n.changeLanguage(languages[nL]);
  }


  return (
    <Box sx={{ display: "flex" }}>
      <BookDemoButton color="#FF9900" fontColor="white" starthovered="true" />
      <NavButton
        key="Language"
        sx={{
          mr: "20px",
          textTransform: "toUpper",
        }}
        onClick={onLanguageClick}
      >
        {languages[currL]}
      </NavButton>
    </Box>
  );
};

import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AboutProductHowItWorks } from "./aboutProductHowItWorks";

export const AboutProduct = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        rowSpacing={{ md: 8, xs: 2 }}
        sx={{
          paddingTop: {
            xs: "100px",
            md: "200px",
          },
          paddingBottom: "50px",
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "min(80%,600px)",
              float: {
                xs: "none",
                md: "right",
              },
              margin: {
                xs: "0 auto",
                md: "0 30px 0 0",
              },
              marginRight: "10px",
            }}
            id={props.id}
          >
            <Typography variant="h3" gutterBottom>
              {t("aboutProduct.header")}
            </Typography>
            <Box
              sx={{ width: "130px", height: "5px", backgroundColor: "#FF9900" }}
            ></Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: {
                xs: "min(80%,600px)",
                md: "min(80%,550px)",
              },
              margin: {
                xs: "30px auto",
                md: "0 0 0 10px",
              },
            }}
          >
            <Typography paragraph variant="h4" sx={{ fontFamily: "Aeonik" }}>
              {t("aboutProduct.header3")}
            </Typography>
            <Typography paragraph variant="body1">
              {t("aboutProduct.description")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: "min(80%,600px)",
              float: {
                xs: "none",
                md: "right",
              },
              margin: {
                xs: "0 auto",
                md: "0 30px 0 0",
              },
              marginRight: "10px",
            }}
          >
            <Typography paragraph variant="h4" sx={{ fontFamily: "Aeonik" }}>
              {t("aboutProduct.headerAr")}
            </Typography>
            <Typography paragraph variant="body1">
              {t("aboutProduct.descriptionAr")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: {
                xs: "min(80%,600px)",
                md: "min(80%,550px)",
              },
              margin: {
                xs: "30px auto",
                md: "0 0 0 10px",
              },
            }}
          >
            <Typography paragraph variant="h4" sx={{ fontFamily: "Aeonik" }}>
              {t("aboutProduct.headerAi")}
            </Typography>
            <Typography paragraph variant="body1">
              {t("aboutProduct.descriptionAi")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <AboutProductHowItWorks />
    </>
  );
};

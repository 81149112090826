import React, { useRef, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { CircularProgress } from '@mui/material';

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
    fontWeight: 600,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: p.focusColor,
    fontWeight: 600,
  },
  "& .MuiInput-root:before": {
    borderBottomColor: p.focusColor,
  },
  "& .MuiInput-root:hover": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
  },
  // focused color for input with variant='outlined'
  "& .MuiInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));

export const BookDemoInputForm = () => {
  const { t } = useTranslation();
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const phoneRef = useRef(null);
  const countryRef = useRef(null);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [duringRequest, setDuringRequest] = useState(false);

  const checkEmailValid = (email) => {
    const ret = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    return ret !== null;
  };

  const checkStringValid = (str) => {
    return str != null && str.trim() !== "";
  };

  const validateAndSend = () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    // let hasError = false;
    // if (!checkStringValid(name)) {
    //   setNameError(true);
    //   hasError = true;
    // }
    // if (!checkEmailValid(email)) {
    //   setEmailError(true);
    //   hasError = true;
    // }
    // if (hasError) return;

    // setDuringRequest(true);
    // const contactRequest = {
    //   Name: document.getElementById("nameField").value,
    //   Email: document.getElementById("emailField").value,
    //   PhoneNumber: document.getElementById("phoneField").value,
    //   Company: document.getElementById("companyField").value,
    //   Country: document.getElementById("countryField").value,
    //   WebsiteName: "augmentedvision.pl",
    // };
    // fetch("https://k2jemplu7j.execute-api.eu-central-1.amazonaws.com/Prod/api/ContactForm/", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json, text/plain, */*",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(contactRequest),
    // })
    //   .then(() => {
    //     setHasSent(true);
    //   })
    //   .then(() => () => {})
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     document.getElementById("nameField").value = "";
    //     document.getElementById("emailField").value = "";
    //     document.getElementById("phoneField").value = "";
    //     document.getElementById("countryField").value = "";
    //     document.getElementById("companyField").value = "";
    //     setDuringRequest(false);
    //   });
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        columnSpacing={2}
        rowSpacing={1}
        sx={{
          margin: {
            xs: "0 0px 0 -10px",
            md: "0 auto",
          },
        }}
      >
        <Grid item xs={12}>
          <CssTextField
            id="nameField"
            inputRef={nameRef}
            label={t("contactForm.name")}
            variant="standard"
            focusColor="white"
            required
            InputLabelProps={{ style: { fontWeight: 600 } }}
            sx={{
              width: {
                xs: "90%",
                md: "95%",
              },
            }}
            error={nameError}
            helperText={nameError ? t("contactForm.nameError") : null}
            onChange={(str) => {
              if (checkStringValid(str.target.value)) setNameError(false);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CssTextField
            id="emailField"
            inputRef={emailRef}
            label={t("contactForm.email")}
            variant="standard"
            focusColor="white"
            required
            InputLabelProps={{ style: { fontWeight: 600 } }}
            sx={{
              width: {
                xs: "90%",
                md: "95%",
              },
            }}
            error={emailError}
            helperText={emailError ? t("contactForm.emailError") : null}
            onChange={(str) => {
              if (checkEmailValid(str.target.value)) setEmailError(false);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CssTextField
            id="phoneField"
            label={t("contactForm.phone")}
            variant="standard"
            focusColor="white"
            InputLabelProps={{ style: { fontWeight: 600 } }}
            sx={{ width: "90%" }}
            inputRef={phoneRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CssTextField
            id="countryField"
            label={t("contactForm.country")}
            variant="standard"
            focusColor="white"
            InputLabelProps={{ style: { fontWeight: 600 } }}
            sx={{ width: "90%" }}
            inputRef={countryRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CssTextField
            id="companyField"
            label={t("contactForm.company")}
            variant="standard"
            focusColor="white"
            InputLabelProps={{ style: { fontWeight: 600 } }}
            sx={{ width: "90%" }}
            inputRef={companyRef}
          />
        </Grid>
      </Grid>
      <LoadingButton
        loading={duringRequest}
        loadingIndicator={
          <CircularProgress color="inherit" size={32} sx={{color:"black"}}/>
        }
        sx={{
          width: "130px",
          height: "50px",
          fontSize: "1rem",
          fontWeight: "600",
          backgroundColor: "white",
          color: "black",
          borderRadius: "25px",
          textTransform: "none",
          marginTop: "30px",
          marginLeft: {
            xs: "0px",
            md: "10px",
          },
          "&:hover": {
            backgroundColor: "white",
            color: "black",
          },
        }}
        onClick={validateAndSend}
      >
        {t("bookDemo")}
      </LoadingButton>
      {hasSent && (
        <Typography
          variant="body1"
          sx={{ marginLeft: "10px", marginTop: "15px", color: "white" }}
        >
          {t("contactForm.thanks")}
        </Typography>
      )}
    </>
  );
};

export const ar = {
  translation: {
    pages: {
      product: "عن المنتج",
      features: "المواصفات",
      cases: "لاستخدامات",
      results: "النتائج",
      team: "الفريق",
      contact: "الاتصال",
    },
    mainPage: {
      header1: "AUGMENTED",
      header2: "VISION",
      description:
        "تطبيق للتعليمات الذكية مدعوم بالذكاء الاصطناعي والواقع المعزز",
    },
    aboutProduct: {
      headerAi: "الذكاء الاصطناعي",
      headerAr: "الواقع المعزز",
      header: "نسهّل طريقة لإعداد الأجهزة وتوصيلها",
      header3: "الوضع الحالي",
      description:
        "يحتاج العملاء إلى الاعتماد على التعليمات التقليدية أو استخدام  مركز اتصال لحل مشاكل معالجة أجهزتهم. غالبا ما يستغرق الاتصال بالخط الاستعلامات الكثير من الوقت ويقلل من رضا العملاء عن خدمات شركة معينة. لا يملك العملاء الأدوات المناسبة لحل المشاكل البسيطة بأنفسهم. نحن نعتقد أن تعليمات لعملية تثبيت وإصلاح الأجهزة الإلكترونية ينبغي أن تكون بسيطة قدر الإمكان.",
      descriptionAi:
        "تكشف الخوارزميات عن الأجهزة ومآخذ التوصيل والكابلات. يمكن للعملاء ارتكاب أخطاء، ويعلمهم الذكاء الاصطناعي عن ذلك. كل شيء معبأ في واجهة سهلة الاستخدام.",
      descriptionAr:
        "الصور المجسمة للواقع المعزز والتي يتم عرضها مباشرة على أجهزة العميل، توجههم عبر عملية الإعداد بأكملها.",
      howItWorks: {
        header: "كيف يعمل ذلك؟",
        header1: "افتح التعليمات الذكية",
        description1: "المستخدم يفتح تطبيق الجوال",
        header2: "أظهِر الأجهزة لكاميرا الهاتف",
        description2:
          "تتعرف الكاميرا على جهاز العميل ومآخذه وكابلاته بفضل خوارزميات الذكاء الاصطناعي لدينا.",
        header3: "إعداد جهازك",
        description3:
          "تقوم خوارزميات الذكاء الاصطناعي لدينا بتوجيه العميل خلال عملية التكوين وعرض التعليمات باستخدام الصور المجسمة بالواقع المعزز",
      },
    },
    features: {
      header1: "أمان البيانات ووضع عدم الاتصال",
      description1:
        "يعمل التطبيق دون اتصال بالشبكة، ويتم تنفيذ كافة الحسابات البرمجية دون اتصال على جهاز العميل. لا توجد حاجة إلى نقل أي بيانات من الكاميرا إلى  وضع server.ine عن بعد.",
      header2: "سرعة العمل  الفائقة",
      description2:
        "تعمل خوارزميات الذكاء الاصطناعي في الوقت الفعلي حتى على الأجهزة الأضعف. تتم معالجة البيانات من الكاميرا بالمللي ثانية ويتلقى المستخدم الإرشادات دون الحاجة إلى الانتظار.",
      header3: "إرسال التقارير",
      description3:
        "يمكن للتطبيق جمع بيانات حول كيفية تفاعل المستخدم معه، والمشاكل التي واجهها الجهاز، والمدة التي استغرقها حل المشكلة.",
    },
    cases: {
      item1: {
        title: "الاتصالات السلكية",
        header: "الرؤية المعززة تخفض تكاليف الدعم الفني وتحسن تجربة المستخدم",
        description:
          "عملية تثبيت سهلة وممتعة للمستخدم تؤثر بشكل إيجابي على مشاركة العملاء مع العلامة التجارية. وفي الوقت نفسه، يتم تخفيض تكاليف التشغيل المرتبطة بمركز الاتصال",
      },
      item2: {
        title: "الطب",
        header:
          "تقلل الرؤية المعززة من الأخطاء المحتملة في تثبيت وتشغيل الأجهزة الطبية.",
        description:
          "يمكن لمصنعي الأجهزة الطبية دعم الكوادر الطبية في استخدام وإعداد أجهزة ومعدات معقدة.",
      },
      item3: {
        title: "الصناعة",
        header:
          "تساهم الرؤية المعززة في أتمتة التدريب والتهيئة وصيانة المنشآت.",
        description:
          "يضع العاملون التعليمات التقليدية جانبا ويمسحون الجهاز بهاتفهم / جهازهم اللوحي لرؤية الصور المجسمة للواقع المعزز مع معلومات حول تعليمات المستخدم أو الخدمة أو تاريخ الصيانة.",
      },
    },
    results: {
      header:
        "النتائج بعد اختبارات الرؤية المعززة في أكبر شركة اتصالات بولندية",
      item1: {
        result: "40%",
        description:
          "لقد قل عدد المكالمات بطلب المساعدة في عملية التثبيت.",
      },
      item2: {
        result: "84%",
        description:
          "صنف العملاء الحل على أنه مفيد جدا لتوصيل المعدات وإعدادها.",
      },
    },
    team: {
      header: "من نحن؟",
      description: "فريق منسق جيدا يتمتع بخبرة واسعة",
    },
    bookDemo: "طلب عرض",
    contactForm: {
      header: "لنبق على اتصال!",
      description: "إذا كانت لديك أي أسئلة، يرجى الاتصال بنا.",
      name: "الاسم",
      email: "عنوان البريد الإلكتروني",
      phone: "الهاتف",
      company: "المنظمة",
      country: "الدولة",
      nameError: "الاسم غير صحيح",
      emailError: "عنوان البريد الإلكتروني غير صحيح",
      thanks: "شكرًا ، سنتواصل معك قريبًا!",
    },
  },
};
  
import React from "react";
import Box from "@mui/material/Box";

export const Section = (props) => {
  const backgroungString = "radial-gradient(ellipse farthest-corner at "
    .concat(props.side)
    .concat("52%, #303030 0%, #141414 62%);");
  return (
    <Box
      {...props}
      sx={{
        ...props["sx"],
        minHeight: "100vh",
        width: "100%",
        paddingBottom: "50px",
        background: backgroungString,
        ...props["sx"],
      }}
    >
      {props.children}
    </Box>
  );
};

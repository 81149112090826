import React from "react";
import { Box, Typography, Grid } from "@mui/material";

export const FeatureItem = (props) => {
  const Icon = props.icon
  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: "50px",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={{ xs: 5, md2: 10 }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              md: "40vw",
            },
          }}
          order={{ xs: 2, md: props.isRight ? 2 : 1 }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "75%",
              },
              textAlign: {
                xs: "center",
                md: props.isRight ? "right" : "left",
              },
              float: {
                xs: "left",
                md: props.isRight ? "right" : "left",
              },
            }}
          >
            <Typography variant="h5" paragraph>
              {props.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: {
                  xs: "75%",
                  md: "100%",
                },
                margin: "0 auto",
              }}
            >
              {props.description}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs="auto" order={{ xs: 1, md: props.isRight ? 1 : 2 }}>
          <Icon
            sx={{
              width: "auto",
              height: "120px",
            }}
          ></Icon>
        </Grid>
      </Grid>
    </Box>
  );
};

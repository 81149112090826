import * as types from "redux/actiontypes";

// reducer

const initialState = {
  snackBar: {
    open: false,
    message: "",
    severity: "success",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  },
  backdropVisible: false,
};

function stateReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SNACKBAR: {
      return {
        ...state,
        snackBar: {
          ...state.snackBar,
          open: action.value.open,
          message: action.value.message,
          severity: action.value.severity,
          acnhorOrigin: action.value.anchorOrigin,
        },
      };
    }
    case types.SET_BACKDROP: {
      return {
        ...state,
        backdropVisible: action.value,
      };
    }
    default:
      return state;
  }
}

export default stateReducer;

import React from "react";
import { Box } from "@mui/system";
import { Grid, Stack, Typography, Link, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Scale from "assets/scale.png";
import Space from "assets/space.png";
import Ue from "assets/ue-logo.png";
import Fepoir from "assets/fepoir.png";
import Parp from "assets/parp.png";
import MarkaPolska from "assets/markaPolska.png";
import AvLogoBlack from "assets/avLogoBlack.svg";

const Img = styled("img")``;

export const Footer = (props) => {
  const handleNavButtonClicked = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <Stack sx={{ backgroundColor: "#f4f4f4", paddingBottom: "5vh" }}>
      <Grid
        container
        direction="row"
        alignContent="center"
        columnSpacing={1}
        rowSpacing={3}
        sx={{
          width: "90%",
          margin: "0 auto",
          justifyContent: {
            xs: "center",
            sm: "flex-start",
          },
          paddingTop: "20px",
          paddingBottom: "30px",
        }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: "90%",
              sm: "50%",
              md: "35%",
              md2: "25%",
            },
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="space-between"
            spacing={1}
          >
            {props.pages.map((page, i) => (
              <Grid
                item
                xs={6}
                key={i}
                sx={{
                  textAlign: {
                    xs: "left",
                    sm: "left",
                  },
                }}
              >
                <Link
                  component="button"
                  variant="body1"
                  underline="none"
                  sx={{ color: "black", fontWeight: 600, textAlign: "center" }}
                  onClick={() => handleNavButtonClicked(page.id)}
                >
                  {page.name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Stack>
            <Link
              href="https://www.linkedin.com/company/augmentedvision/"
              target="_blank"
              rel="noreferrer"
              sx={{ textAlign: "center" }}
            >
              <IconButton sx={{ margin: "0 auto" }}>
                <LinkedInIcon
                  sx={{
                    color: "black",
                    width: "35px",
                    height: "35px",
                    borderRadius: "10px",
                  }}
                />
              </IconButton>
            </Link>
            <Typography
              sx={{ color: "black", fontWeight: 600, textAlign: "center" }}
            >
              Follow Us
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={true}></Grid>
        <Grid
          item
          xs={12}
          sm="auto"
          sx={{
            textAlign: {
              xs: "center",
              sm: "right",
            },
          }}
        >
          <Img
            src={AvLogoBlack}
            sx={{ marginBottom: "10px", color: "black" }}
          ></Img>
          <Typography sx={{ color: "black", fontWeight: 600 }}>
            j.ciecierski@augmentedvision.pl
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "90%",
          height: "1px",
          backgroundColor: "black",
          margin: "0 auto",
          opacity: 0.25,
          marginTop: "10px",
          marginBottom: "10px",
        }}
      ></Box>
      <Grid
        container
        direction="row"
        alignContent="center"
        columnSpacing={1}
        rowSpacing={3}
        sx={{
          width: "90%",
          margin: "0 auto",
          justifyContent: {
            xs: "center",
            sm: "space-between",
          },
          paddingTop: "20px",
          paddingBottom: "30px",
        }}
      >
        <Grid item xs={12} sm={6} md="auto" sx={{ textAlign: "center" }}>
          <Typography
            variant="body1"
            sx={{
              color: "black",
              fontWeight: 600,
              height: "100%",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            Partners
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md="auto" sx={{ textAlign: "center" }}>
          <Img
            src={Scale}
            sx={{ width: "95%", maxHeight: "60px", maxWidth: "200px" }}
          ></Img>
        </Grid>
        <Grid item xs={12} sm={6} md="auto" sx={{ textAlign: "center" }}>
          <Img
            src={Space}
            sx={{ width: "95%", maxHeight: "60px", maxWidth: "200px" }}
          ></Img>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "90%",
          height: "1px",
          backgroundColor: "black",
          opacity: 0.25,
          margin: "0 auto",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      ></Box>
      <Grid
        container
        direction="row"
        alignContent="stretch"
        columnSpacing={1}
        rowSpacing={5}
        sx={{
          width: "90%",
          margin: "0 auto",
          marginTop: "0",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={4} md={3} sx={{ textAlign: "center" }}>
          <Img
            src={MarkaPolska}
            sx={{ maxHeight: "100px", marginTop: "-15px" }}
          ></Img>
        </Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ textAlign: "center" }}>
          <Img src={Parp} sx={{ maxHeight: "30px", marginTop: "5px" }}></Img>
        </Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ textAlign: "center" }}>
          <Img src={Ue} sx={{ maxHeight: "45px" }}></Img>
        </Grid>
        <Grid item xs={12} sm={4} md={3} sx={{ textAlign: "center" }}>
          <Img
            src={Fepoir}
            sx={{ maxHeight: "60px", marginTop: "-10px" }}
          ></Img>
        </Grid>
      </Grid>
    </Stack>
  );
};

import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { VideoPage } from "../components/mainPage";
import { Section } from "../components/misc";
import { NavBar } from "../components/navigation";
import { AboutProduct } from "../components/aboutPage";
import { BookDemo } from "../components/bookDemoPage";
import { Features } from "../components/featuresPage";
import { Cases } from "../components/casesPage";
import { Team } from "../components/teamPage";
import { Footer } from "../components/footerPage";
import { Results } from "../components/resultsPage";
import { AppImages } from "../components/appImages";

export const AugmentedVisionLanding = () => {
  const { t } = useTranslation();
  const pages = [
    {
      id: "about",
      name: t("pages.product"),
    },
    {
      id: "features",
      name: t("pages.features"),
    },
    {
      id: "cases",
      name: t("pages.cases"),
    },
    {
      id: "results",
      name: t("pages.results"),
    },
    {
      id: "team",
      name: t("pages.team"),
    },
    {
      id: "bookDemo",
      name: t("pages.contact"),
    },
  ];
  return (
    <>
      <NavBar pages={pages} />
      <VideoPage />
      <Box
        sx={{ width: "100%", height: "6px", backgroundColor: "#FF9900" }}
      ></Box>
      <Section side="0%" i={1}>
        <AboutProduct id="about" />
        <Features id="features" />
      </Section>
      <Section side="100%">
        <Cases id="cases" />
        <Results id="results" />
      </Section>
      {/* <Testimonials /> */}
      <Section side="100%" sx={{ paddingBottom: 0 }}>
        <Team id="team" />
      </Section>
      <AppImages/>
      <BookDemo id="bookDemo" />
      <Footer pages={pages} />
    </>
  );
};
//sx={{ transform: "scaleY(-1)" }}

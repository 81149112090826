import React, {useState, useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Grid, Slide } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { styled } from "@mui/material/styles";

import { NavButton } from "./navButton";
import { NavRightBar } from "./navRightBar";

import AvLogo from "assets/avLogo.svg";

const Img = styled("img")``;

export const DesktopNavBar = (props) => {
  const [isOnTop, setIsOnTop] = useState(true);
  const [scrollToSection, setScrollToSection] = useState(false);

  const trigger = useScrollTrigger({ disableHysteresis: scrollToSection, });
  const handleNavButtonClicked = (elementId) => {
    setScrollToSection(true)
    const element = document.getElementById(elementId);
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
    setIsOnTop(true);
    setTimeout(() => {
      setScrollToSection(false);
    }, 1500);
  };  

  useEffect(() => {
    const onScroll = () => {
      setIsOnTop(Boolean(window.pageYOffset < 50));
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleNavBarVisibility = ()=>{
    if(scrollToSection){
      return false;
    } 
    return !trigger;
  }

  return (
    <Slide appear={false} direction="down" in={handleNavBarVisibility()} unmountOnExit={true}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: (isOnTop) ? "transparent" : "rgba(0,0,0,0.9)", boxShadow: "none" }}
      >
        <Toolbar disableGutters>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ display: "flex" }}>
              <Img
                src={AvLogo}
                sx={{
                  width: "40px",
                  height: "40px",
                  marginTop: "15px",
                  marginLeft: "30px",
                }}
              />
              {props.pages.map((page) => (
                <NavButton
                  key={page.name}
                  sx={{ ml: "15px" }}
                  onClick={() => handleNavButtonClicked(page.id)}
                >
                  {page.name}
                </NavButton>
              ))}
            </Box>
            <NavRightBar />
          </Grid>
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

import React from "react";
import { Typography, Box } from "@mui/material";

export const CircularIndex = (props) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: {
          xs: "65px",
          sm: "85px",
        },
        height: {
          xs: "65px",
          sm: "85px",
        },
        border: "2px solid #FFFFFF",
        margin:"0 auto"
      }}
    >
      <Typography
        variant="h3"
        sx={{
          background: "linear-gradient(180deg, #FF9900 0%, #B73700 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontFamily: "Aeonik",
          fontWeight: "300",
        }}
      >
        0{props.index}
      </Typography>
    </Box>
  );
};

export const pl = {
  translation: {
    pages: {
      product: "O Produkcie",
      features: "Specyfikacja",
      cases: "Zastosowanie",
      results: "Rezultaty",
      team: "Zespół",
      contact: "Kontakt",
    },
    mainPage: {
      header1: "AUGMENTED",
      header2: "VISION",
      description:
        "Aplikacja do inteligentnych instrukcji zasilana przez AI & AR",
    },
    aboutProduct: {
      headerAi: "Sztuczna Inteligencja",
      headerAr: "Rozszerzona Rzeczywistość",
      header: "Uproszczamy sposób konfiguracji i\u00a0podłączania urządzeń.",
      header3: "Obecna sytuacja",
      description:
        "Klienci muszą polegać na tradycyjnych instrukcjach lub skorzystać z pomocy call center, aby rozwiązać swoje problemy z obsługą urządzeń. Często kontakt z infolinią zajmuje dużo czasu i zmniejsza satysfakcję Klienta z usług danej firmy. Klienci nie mają odpowiednich narzędzi do samodzielnego rozwiązywania prostych problemów. Uważamy, że instrukcje dotyczące procesu instalacji i naprawy urządzeń elektronicznych powinny być jak najprostsze.",
      descriptionAi:
        "Algorytmy wykrywają urządzenia, gniazda i kable. Klienci mogą popełniać błędy, a sztuczna inteligencja poinformuje ich o tym. Wszystko zapakowane w przyjazny dla użytkownika interfejs.",
      descriptionAr:
        "Hologramy AR, które są wyświetlane bezpośrednio na urządzeniach klienta, prowadzą ich przez cały proces konfiguracji.",
      howItWorks: {
        header: "Jak to działa?",
        header1: "Otwórz inteligentną instrukcję",
        description1: "Użytkownik otwiera aplikację mobilną",
        header2: "Pokaż urządzenia do kamery telefonu",
        description2:
          "Kamera rozpoznaje urządzenie klienta, gniazda i kable dzięki naszym algorytmom AI.",
        header3: "Skonfiguruj urządzenie",
        description3:
          "Nasze algorytmy AI prowadzą klienta przez proces konfiguracji i za pomocą hologramów AR wyświetlają instrukcję",
      },
    },
    features: {
      header1: "Bezpieczeństwo danych i tryb offline",
      description1:
        "Aplikacja działa w trybie offline. Wszystkie obliczenia są wykonywane offline na urządzeniu klienta. Żadne dane z kamery nie muszą być przesyłane na zdalny serwer.ine mode.",
      header2: "Błyskawiczne działanie",
      description2:
        "Algorytmy AI działają w czasie rzeczywistym nawet na słabszych urządzeniach. Dane z kamery są przetwarzane w milisekundach, a użytkownik otrzymuje instrukcje bez konieczności czekania.",
      header3: "Raportowanie",
      description3:
        "Aplikacja może zbierać dane o tym, jak użytkownik wchodzi z nią w interakcję, jakie wystąpiły problemy z urządzeniem i ile czasu zajęło rozwiązanie problemu.",
    },
    cases: {
      item1: {
        title: "TELEKOMUNIKACJA",
        header:
          "AV obniża koszty pomocy technicznej i poprawia wrażenia użytkownika.",
        description:
          "Łatwy i przyjemny dla użytkownika proces instalacji urządzeń pozytywnie wpływa na zaangażowanie klientów w markę. Jednocześnie koszty operacyjne związane z call center są zmniejszone.",
      },
      item2: {
        title: "MEDYCYNA",
        header:
          "AV ogranicza potencjalne błędy w instalacji i obsłudze urządzeń medycznych.",
        description:
          "Producenci urządzeń medycznych mogą wspierać personel kliniczny w korzystaniu i konfigurowaniu skomplikowanego sprzętu.",
      },
      item3: {
        title: "PRZEMYSŁ",
        header: "AV automatyzuje szkolenia, onboarding i utrzymanie zakładu.",
        description:
          "Pracownicy odkładają na bok tradycyjną instrukcję i skanują maszynę za pomocą telefonu/tabletu, aby zobaczyć hologramy AR z informacjami o instrukcjach obsługi, serwisowaniu lub historii konserwacji.",
      },
    },
    results: {
      header: "Rezultaty po testach AV w największej polskiej firmie telekomunikacyjnej",
      item1: {
        result: "40%",
        description:
          "Mniej telefonów z prośbą o pomoc dotyczącą procesu instalacji niż wcześniej.",
      },
      item2: {
        result: "84%",
        description:
          "Klienci ocenili rozwiązanie jako bardzo przydatne do podłączenia i konfiguracji sprzętu.",
      },
    },
    team: {
      header: "Kim jesteśmy?",
      description: "Zgrany zespół z\u00a0szerokim doświadczeniem",
    },
    bookDemo: "Zamów pokaz",
    contactForm: {
      header: "Pozostańmy w kontakcie!",
      description: "Jeżeli masz jakieś pytania prosimy o\u00a0kontakt.",
      name: "Imię",
      email: "Adres email",
      phone: "Telefon",
      company: "Organizacja",
      country: "Kraj",
      nameError: "Imię nieprawidłowe",
      emailError: "Email nieprawidłowy",
      thanks: "Dziękujemy, odezwiemy się wkrótce!",
    },
  },
};

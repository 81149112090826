import React from "react";
import { Box } from "@mui/system";
import { IconButton, Stack, Typography, Link } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { styled } from "@mui/material/styles";
const Img = styled("img")``;

export const Person = (props) => {
  return (
    <Box sx={{ width: "220px", margin: "0 auto", padding: "10px" }}>
      <Img src={props.imgSrc} sx={{ width: "100%" }} />
      <Box
        sx={{
          width: "100%",
          height: "3px",
          backgroundColor: "#FF9900",
          marginTop: "-6px",
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ marginTop: "15px" }}
      >
        <Box xs="auto">
          <Typography variant="h5" sx={{ fontFamily: "Aeonik" }}>
            {props.name}
          </Typography>
          <Typography variant="h5" sx={{ fontFamily: "Aeonik" }}>
            {props.lastName}
          </Typography>
        </Box>
        <Link href={props.linkedin} target="_blank" rel="noreferrer">
          <IconButton>
            <LinkedInIcon
              sx={{ color: "white", width: "35px", height: "35px" }}
            />
          </IconButton>
        </Link>
      </Stack>
      <Typography variant="caption">{props.position}</Typography>
    </Box>
  );
};

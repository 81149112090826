import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider } from "@apollo/client";
import { useAuth } from "context";
import { GRAPHQL_ENDPOINT_PROD, GRAPHQL_LOCAL } from "config";

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT_PROD,
  //uri: GRAPHQL_LOCAL,
});

export const ApolloClientProvider = (props) => {
  const auth = useAuth();
  const token = auth.user ? auth.user.jwtToken : null;
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });
  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  if (token) apolloClient.resetStore();
  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};

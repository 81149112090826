import React from "react";
import { Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ResultItem = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={matches ? 0 : 4}
      sx={{ paddingTop: "30px", paddingBottom: "30px" }}
    >
      <Grid
        item
        sx={{
          width: {
            xs: 0,
            md: "calc(50vw - min(40vw,630px))",
          },
          height: "100px",
          marginLeft: {
            xs: 0,
            md: "-10px",
          },
        }}
        xs="auto"
      ></Grid>
      <Grid
        item
        sx={{
          marginLeft: "0",
          justifyContent: "center",
        }}
        xs={matches ? 12 : "auto"}
      >
        <Typography
          variant="h3"
          sx={{
            background: "linear-gradient(180deg, #FF9900 0%, #B73700 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily: "Aeonik",
            fontWeight: "300",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

          }}
        >
          {props.result}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          textAlign: {
            xs: "center",
            md: "right",
          },
          marginTop: {
            xs: "0px",
            sm: "30px",
            md: 0,
          },
        }}
        xs={matches ? 12 : true}
      >
        <Typography
          variant="h5"
          sx={{
            maxWidth: "80vw",
            margin: matches ? "0 auto" : "0 20vw 0 0",
            fontFamily: "inherit",
          }}
        >
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FeatureItem } from "./featureItem";
import { DashedLine } from "../common";

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';

export const Features = (props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: {
          xs: "15vh",
          md: "30vh",
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          width: "min(90%,1260px)",
          margin: "0 auto",
          paddingLeft: {
            xs: "5px",
            md: 0,
          },
        }}
        id={props.id}
      >
        {t("pages.features")}
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        rowSpacing={5}
        sx={{
          width: "100%",
          margin: {
            xs: "50px auto",
            md: "100px auto",
          },
        }}
      >
        <Grid item xs={12}>
          <FeatureItem
            icon={ShieldOutlinedIcon}
            name={t("features.header1")}
            description={t("features.description1")}
          />
        </Grid>
        <DashedLine />
        <Grid item xs={12}>
          <FeatureItem
            icon={OfflineBoltOutlinedIcon}
            name={t("features.header2")}
            description={t("features.description2")}
            isRight={true}
          />
        </Grid>
        <DashedLine />
        <Grid item xs={12}>
          <FeatureItem
            icon={AssessmentOutlinedIcon}
            name={t("features.header3")}
            description={t("features.description3")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";
import { Box } from "@mui/material";

export const DashedLine = () => {
  return (
    <Box
      sx={{
        width: "95%",
        height: "1px",
        backgroundImage: "linear-gradient(to right, white 55%, transparent 0%)",
        opacity: 0.25,
        backgroundPosition: "bottom;",
        backgroundSize: "30px 1px",
        backgroundRepeat: "repeat-x",
        margin: "0 auto",
      }}
    ></Box>
  );
};

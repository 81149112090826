import React from "react";
import { Button } from "@mui/material";

export const NavButton = (props) => {
  return (
    <Button
      disableRipple
      {...props}
      sx={{
        my: 2,
        color: "white",
        display: "block",
        textTransform: "none",
        "&:hover": {
          ...props.sx["&:hover"],
          backgroundColor: "transparent",
          borderColor: "transparent",
          boxShadow: "none",
          color: "gray",
        },
        "&:focusVisible": {
          boxShadow: "none",
          backgroundColor: "transparent",
          borderColor: "red",
          color: "blue",
        },
        ...props.sx,
      }}
    >
      {props.children}
    </Button>
  );
};
